import useUser from "./usersManage/useUser";

// const URL_DOMAIN = "http://127.0.0.1:8000/ws/web";
// const URL_DOMAIN = "https://development.foodpanel.co/de/ws/web";
//const URL_DOMAIN = "https://development.foodpanel.co/de/ws/web";
//const URL_DOMAIN = "https://development.foodpanel.co/de";
//const URL_REPORT = "https://development.foodpanel.co/de/ws/reports";
/**
 * PROD DOMAIN
const URL_DOMAIN_ROOT = "https://web.kuruah.com/ws";
const URL_DOMAIN = "https://web.kuruah.com/ws/web";
const URL_REPORT = "https://web.kuruah.com/ws/reports";
**/

/**
 *DEV DE DOMAIN
 */
const URL_DOMAIN = "https://development.kuruah.com/de/ws/web";
const URL_DOMAIN_ROOT = "https://development.kuruah.com/de/ws";
const URL_REPORT = "https://development.kuruah.com/de/ws/reports";


// const URL_DOMAIN_ROOT = "http://127.0.0.1:8000/ws";
// const URL_DOMAIN = "http://127.0.0.1:8000/ws/web";
// const URL_REPORT = "http://127.0.0.1:8000/ws/reports";


const URL_P2 = [
  "/franchises",
  "/operators",
  "/products",
  "/authentication",
  "/ingredients",
  "/supervisor",
  "/company",
  "/invoice-schemas",
  "/information",
  "/demo",
  "/inventory-transactions",
  "/provider"
];

const URL_INVOICE_SCHEMAS = URL_DOMAIN + URL_P2[7];

const URL_OPERATORS = URL_DOMAIN + URL_P2[1];

const URL_AUTH = URL_DOMAIN + URL_P2[3];

const URL_PRODUCTS = URL_DOMAIN + URL_P2[2];
const URL_INGREDIENTS = URL_DOMAIN + URL_P2[4];

const URL_SUPERVISOR = URL_DOMAIN + URL_P2[5];
const URL_COMPANY = URL_DOMAIN + URL_P2[6];

let myHeaders = new Headers();
if (localStorage.getItem("bckt")) {
  const auth_user = JSON.parse(localStorage.getItem("profile")).username;
  const token = window.localStorage.getItem("bckt");
  console.log("User", auth_user);
  const megaToken = "Basic " + btoa(auth_user + ":" + token);
  myHeaders.append("Authorization", megaToken);
}

var requestOptionsGet = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};
var requestOptionsPost = {
  method: "POST",
  body: "",
  headers: myHeaders,
  redirect: "follow",
};

var requestOptionsPut = {
  method: "PUT",
  body: "",
  headers: myHeaders,
  redirect: "follow",
};

var requestOptionsDelete = {
  method: "DELETE",
  body: "",
  headers: myHeaders,
  redirect: "follow",
};

const getJSON = (response) => response.json();

export {
  URL_DOMAIN,
  URL_P2,
  URL_REPORT,
  URL_DOMAIN_ROOT,
  getJSON,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsDelete,
};
