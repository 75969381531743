import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import "./searchBar.scss";
import "../../index.scss";

const SearchBar = ({
  input: keyword,
  onChange: setKeyword,
  nombre: name,
  add: agregar,
  disabled,
  intl
}) => {
  const BarStyling = {
    fontFamily: "Muli-Regular",
  };
  const btnStyling = {
    position: "relative",
    left: "89%",
    top: "0%",
    margin: "0",
  }; 
  return (
    <div>
      {agregar && (
        <Link to="/recetas/nuevaReceta">
          <button type="button" className="btn btn-primary btn-style">
            +
          </button>
        </Link>
      )}
      
      <TextField
      className="input-style input-text-field-style"
        key="random1"
        style={BarStyling}
        id="search-bar"
        value={keyword}
        placeholder={ name }
        onChange={(e) => setKeyword(e.target.value)}
        disabled={disabled? disabled: false}
      />
    </div>
  );
};

export default injectIntl(SearchBar);
