import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import DownloadLink from "react-download-link";
import {
  getTransactionsForProductByFranchiseDateRange,
  getProductAvailabilityInFranchise,
  getProudctInventoryGraph,
} from "../../../services/utilsInventory/utilsInventory";
import { URL_REPORT } from "../../../services/globals";

import {
  FormControlLabel,
  TextField,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import { Line, ResponsiveLine } from "@nivo/line";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import CustomTable from "../../../components/Table/Table";
import DownloadLinkCustom from "../../../components/DownloadLinkCustom/DownloadLinkCustom";
import "./ProductInventory.scss";
import DownloadPrimaryIcon from "../../../assets/icons/file_download_black_24dp.svg";

const ProductInventory = () => {
  const [seeTable, setSeeTable] = useState(true);

  const [visualizationMode, setVisualizationMode] = useState(1);
  const [dates, setDates] = useState({
    date_initial: "",
    date_final: "",
  });

  const [availabilityProduct, setAvailabilityProduct] = useState({});

  const [inventoryMovements, setInventoryMovements] = useState([]);

  const [pagination, setPagination] = useState({});

  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);

  const [graphLoading, setGraphLoading] = useState(true);

  const inventoryMovementTableHeaders = [
    "dateHeader",
    "typeMovementHeader",
    "quantityHeader",
    "balanceQuantityHeader",
    "referenceHeader",
  ];
  const [dataChart, setDataChart] = useState([
    {
      id: "",
      color: "",
      data: [
        { x: "", y: "" },
        { x: "", y: "" },
      ],
    },
  ]);

  const setVisualization = (event) => {
    let tableView = seeTable;
    setSeeTable(!tableView);
  };

  const handleVisualizationMode = (event) => {
    setVisualizationMode(event.target.value);
    let tableView = seeTable;
    setSeeTable(!tableView);
  };
  const handleInputChangeInner = (event) => {
    let initialDate = dates.date_initial;
    if (event.target.name === "date_final") {
      if (initialDate <= event.target.value) {
        setDates({ ...dates, [event.target.name]: event.target.value });
      } else {
        setDates({ ...dates, [event.target.name]: "" });
      }
    } else {
      setDates({ ...dates, [event.target.name]: event.target.value });
    }
  };

  let createTableMovementsData = (
    date,
    typeMovement,
    quantity,
    balanceQuantity,
    referenceDocument
  ) => {
    return {
      date,
      typeMovement,
      quantity,
      balanceQuantity,
      referenceDocument,
    };
  };
  let typeMovement = (type) => {
    if (type === 0) {
      return "Compra";
    } else if (type === 20) {
      return "Devolución venta";
    } else if (type === 21) {
      return "Devolucion compra";
    } else if (type === 10) {
      return "Entrada forzada";
    } else if(type === 11) {
      return "Salida forzada"
    } else {
      return "Venta";
    }
  };
  const fetchData = async () => {
    let productId = localStorage.getItem("fk_product");
    let franchiseId = localStorage.getItem("fk_franchise");
    let initialDate = dates.date_initial;
    let finalDate = dates.date_final;
    //createDataLine(otherData);

    getProductAvailabilityInFranchise(franchiseId, productId).then((data) => {
      setAvailabilityProduct(data);
    });
    if (initialDate !== "" && finalDate !== "") {
      if (initialDate <= finalDate) {
        if (seeTable) {
          getTransactionsForProductByFranchiseDateRange(
            franchiseId,
            productId,
            initialDate,
            finalDate
          ).then((data) => {
            console.log("Transactions data", data);
            let inventoryMovementsData = data.inventory_transaction_details.map(
              (movement) =>
                createTableMovementsData(
                  movement.date_request.replace("T", " "),
                  typeMovement(movement.type),
                  movement.quantity,
                  movement.current_amount,
                  movement.invoice_number
                )
            );
            setInventoryMovements(inventoryMovementsData);
            setLoading(false);
            setTableLoading(false);
          });
        } else {
          //ACA VAMOS A LLAMAR AL GRAFICOOO

          getProudctInventoryGraph(
            franchiseId,
            productId,
            initialDate,
            finalDate
          ).then((data) => {
            console.log("Graph data", data);
            let dataObj = data.data_tuple;
            let dataTuple = [];
            for (var key in dataObj) {
              var value = dataObj[key];
              let data = { x: key, y: value };
              dataTuple.push(data);
            }

            let graphObject = [
              {
                id: "Inventario",
                data: dataTuple,
              },
            ];

            console.log(graphObject);
            setGraphLoading(false);
            setDataChart(graphObject);
          });
        }
      } else {
        console.log(" QUIERE VIAJAR EN EL TIEMPO O K");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [dates, seeTable]);

  let createUrlDownload = () => {
    let productId = localStorage.getItem("fk_product");
    let franchiseId = localStorage.getItem("fk_franchise");
    let initialDate = dates.date_initial;
    let finalDate = dates.date_final;
    return (
      URL_REPORT +
      `/get-product-movements-report-for-franchise-and-date-range?fk_franchise=${franchiseId}&start_date=${initialDate}&end_date=${finalDate}&fk_product=${productId}`
    );
  };
  //Try aws
  return (
    <>
      <div className="row no-margin">
        <div className="col-4 no-padding">
          <main className="section-2body">
            <article className="center-items">
              <img
                src={availabilityProduct.image ? availabilityProduct.image : ""}
                alt=""
                className="product_img_inventory"
              />
            </article>
            <section>
              <article>
                <h1 className="section-title">{availabilityProduct.name}</h1>
                <p className="grey-front">{localStorage.getItem("franchiseName")}</p>
              </article>
              <article>
                <div className="flex space all-balance-info">
                  <p>
                    {" "}
                    <FormattedMessage id="last_forced_movement" />
                  </p>
                  <p className="purple-font">
                    {availabilityProduct.last_inventory_confirmation
                      ? availabilityProduct.last_inventory_confirmation.quantity
                      : 0}{" "}
                    {availabilityProduct.unit}
                  </p>
                </div>
                <div className="flex items-to-end">
                  <p>
                    {availabilityProduct.last_inventory_confirmation
                      ? availabilityProduct.last_inventory_confirmation.date
                      : ""}
                  </p>
                </div>
              </article>
              <article className="flex space all-balance-info">
                <p className="purple-font">
                  <FormattedMessage id="allSales" />
                </p>
                <p className="purple-font">
                  {availabilityProduct.products_in} {availabilityProduct.unit}
                </p>
              </article>
              <article className="flex space all-balance-info">
                <p className="purple-font">
                  <FormattedMessage id="allBuys" />
                </p>
                <p className="red-font">
                  {"-"}
                  {availabilityProduct.products_out} {availabilityProduct.unit}
                </p>
              </article>
              <article className="flex space all-balance-info purple-font total-border">
                <h4>
                  <FormattedMessage id="all" />
                </h4>
                <h4>
                  {availabilityProduct.availability} {availabilityProduct.unit}
                </h4>
              </article>
              <div className="col-12 d-flex justify-content-end">
                <Link className="" to={`/inventarios`}>
                  <button
                    className="btn btn-outline-primary m-2"
                    id="inventory-manager"
                  >
                    <FormattedMessage id="inventoryManager" />
                  </button>
                </Link>
              </div>
            </section>
          </main>
        </div>
        <div className="col no-padding">
          <main className="section-2body">
            <div className="flex space">
              <h1 className="section-title">
                <FormattedMessage id="movementsHistory" />
              </h1>

              {dates.date_initial && dates.date_final && (
                <DownloadLinkCustom
                  label={<img src={DownloadPrimaryIcon} alt="" className="" />}
                  filename="historico_inventario"
                  url={createUrlDownload()}
                />
              )}
            </div>
            <form action="">
              <article className="flex">
                <TextField
                  id="date"
                  label={<FormattedMessage id="aprovalDateLbl" />}
                  type="date"
                  name="date_initial"
                  className="form-control input-margin date-input"
                  onChange={handleInputChangeInner}
                  value={dates.date_initial ? dates.date_initial : ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  disabled={dates.date_initial === ""}
                  label={<FormattedMessage id="aprovalDateLbl" />}
                  type="date"
                  name="date_final"
                  className="form-control input-margin date-input"
                  onChange={handleInputChangeInner}
                  value={dates.date_final ? dates.date_final : ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </article>
            </form>
            <article className="flex">
              <RadioGroup
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={visualizationMode}
                onChange={handleVisualizationMode}
                className="flex"
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label={<FormattedMessage id="tableLbl" />}
                  className="font-muli"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label={<FormattedMessage id="graphLbl" />}
                />
              </RadioGroup>
            </article>
            <article>
              {graphLoading && !seeTable && (
                <>
                  <h5>
                    <FormattedMessage id="choseeRangeDateLbl" />
                  </h5>
                </>
              )}
              {!graphLoading && !seeTable && dataChart && (
                <>
                  <div className="graph-container">
                    <div className="graph-tamanio">
                      <ResponsiveLine
                        data={dataChart}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: true,
                          reverse: false,
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "Fechas",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "Inventario",
                          legendOffset: -40,
                          legendPosition: "middle",
                        }}
                        pointSize={10}
                        colors={["#78065D", "#FCA235", "#FF003A"]}
                        pointColor="#78065D"
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                          {
                            anchor: "bottom-right",
                            direction: "column",
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: "left-to-right",
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: "circle",
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                              {
                                on: "hover",
                                style: {
                                  itemBackground: "rgba(0, 0, 0, .03)",
                                  itemOpacity: 1,
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                  </div>
                </>
              )}

              {tableLoading && seeTable && (
                <>
                  <h5>
                    <FormattedMessage id="choseeRangeDateLbl" />
                  </h5>
                </>
              )}
              {!loading && seeTable && (
                <div className="table-inventory-container">
                  {tableLoading && <LinearProgress />}
                  <CustomTable
                    headers={inventoryMovementTableHeaders}
                    rows={inventoryMovements}
                    pagination={pagination}
                  />
                </div>
              )}
            </article>
          </main>
        </div>
      </div>
    </>
  );
};

export default ProductInventory;
